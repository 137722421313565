import React from "react"

export const pageFade = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}

export const pageTransistion = {
  duration: 0.7,
  ease: "easeIn",
}
